._custom_regular_button{
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
    border-radius: 63px;
    cursor: pointer;
    user-select: none;
    tap-highlight-color: transparent;
    -moz-user-select: none;
    -ms-user-select: none;
    box-shadow: none;
    outline: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-tap-highlight-color: transparent;
}
._custom_regular_button:active{
    outline: none;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-tap-highlight-color: transparent;
}
._custom_small_button{
    text-align: center;
}
