.TaskCard div{
    display: inline-block;
    vertical-align: top;
}
.TaskCard span{
    line-height: 1.5;
}
.TaskCard{
    background-color: #FFFFFF;
    margin-top: 9px;
    margin-bottom: 11px;
    min-height: 50px;
    border-radius: 24px;
    padding: 24px;
}

.TaskCardSelected{
    background-color: #FFFFFF;
}

.TaskCard_MyTask{
    background-color: #FFFFFF;
    border: #0B7F6C 1.5px solid;
}
.TaskCard_Taken{
    opacity: 0.8;
    background-color: #FFFCF7;
}

.TaskCard_Taken ._black_bold_18{
    opacity: 0.4 !important;
}