.BackButton img {
    width: 36px;
    height: 36px;
}

.BackButton {
    width: 36px !important;
    height: 36px !important;
    padding: 0;
    margin: 0;
}

.BackButton .BackButton_default {
    display: block;
}

.BackButton .BackButton_click,
.BackButton .BackButton_hover {
    display: none;
}

.BackButton:active .BackButton_default,
.BackButton:active .BackButton_hover {
    display: none !important;
}

.BackButton:active .BackButton_click {
    display: block !important;
}

.BackButton:hover .BackButton_default,
.BackButton:hover .BackButton_click {
    display: none;
}

.BackButton:hover .BackButton_hover {
    display: block;
}

.BackButton:disabled {
    opacity: 0.5;
}