h1{
  text-align: center;
}
.App-logo{
  width: 90%;
  max-width: 400px;
  display: flex;
  margin: auto;
}
.App{
  align-items: center;
  background-color: white;
  border-radius: 15px;
  padding: 20px;
 

  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 400px;
  /*height: 180px;*/
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.19);

}

.AppBody{
  width: 100%;
  
  height: 100vh;
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 600px) {
  .AppBody {
    
    background-size: cover;
    background-image: url('images/background_desktop.png');
  }
}
@media screen and (max-width: 599px) {
  .App{
    width: 75%;
  }
  .AppBody {
    background-repeat: no-repeat;
    background-size: cover;
    /*background-color: aqua;*/
    background-image: url('images/background_mobile.png');
  }
}

.AlwaysReadyButton{
  background-color: #313A56;
}

.AlwaysReadyButton:hover{
  background-color: #496177;
}

.AlwaysReadyButton:active{
  background: #161D33;
}
a{
  color: grey;
}
a:hover{
  color: grey;
}
body{
  -webkit-tap-highlight-color: transparent !important;
}