.PageTab{
    font-family: Rubik;
    cursor: pointer;
    border-radius: 12px;
    color: #1F1F1F;
    border: 0.05em solid #1F1F1F;
    display: inline-block;
    margin: 4px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 12px;
    gap: 10px;
    font-size: 14px;
}

.PageTabSelected{
    border: 0.05em solid #057543;
    color: #057642;
}