.AppPageCart{
    color: black;
    background-color: white;
    border-radius: 24px;
    min-height: 50px;
    padding: 24px;
    width: calc(100% - 100px);
    margin: auto;
    margin-top: 12px;
    margin-top: 12px;
}
