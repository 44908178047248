.CollapseDetails {
    display: flex;
    -webkit-tap-highlight-color: transparent;
    flex-direction: column;
    width: 100%;
}

.CollapseSummary table{
    width: 100%;
}
.CollapseSummary {
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    width: 100%;
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    position: relative;
    transition: background-color 0.6s ease-in-out;
}

.CollapseIcon {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 4px;
    top: 5px;
    bottom: 5px;
    transform: rotate(180deg);
    transition: transform 0.6s ease-out;
}

.CollapseIconIw {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 4px;
    top: 5px;
    bottom: 5px;
    transform: rotate(180deg);
    transition: transform 0.6s ease-out;
}

.CollapseSummary {
    background-color: transparent;
    border: none;
    font-weight: 600;
    font-size: 18px;
}

.CollapseSummary:hover {
    color: #075F88;
}

.CollapseSummary:active {
    color: #2080AC;
    font-weight: 600;
}

.CollapseDetailsOpen .CollapseIcon {
    transform: rotate(0deg);
}
.CollapseDetailsOpen .CollapseIconIw {
    transform: rotate(0deg);
}

.CollapseContent {
    margin: 0;
    padding: 2px;
    border: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease-in-out;
    font-size: 16px;
    font-weight: 300;
}

.CollapseDetailsOpen .CollapseContent {
    max-height: 100vh;
    border: none;
    outline: none;
    transition: max-height 0.6s ease-in-out;
}

.CollapseDetailsOpen .CollapseContent p {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}

.CollapseDetails .CollapseContent p {
    opacity: 0;
    transform: translateY(-10px);
}