.LeftFoatingButton{
    position: absolute !important;
    background-color: #F5F8FF;
    border-radius: 18px;
    width: 40px;
    height: 40px;
    left: 15px;
    bottom: 15px;
    z-index: 10;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.45);
}
.LeftFoatingButton[iw_locale]{
    right: 15px !important;
    left: '';
}
.LeftFoatingButton:hover{
    text-align: center;
}
.LeftFoatingButton:active{
    text-align: center;
}
