.DivScroolableStyled::-webkit-scrollbar {
    width: 6px;
    margin: 4px;
}

.DivScroolableStyled::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #daebfa;
    border-radius: 10px;
}

.DivScroolableStyled::-webkit-scrollbar-thumb {
    background: #4A709A;
    border-radius: 10px;
}

.DivScroolableStyled::-webkit-scrollbar-thumb:hover {
    background: #8c959b;
}

.DivScroolableStyled::-webkit-scrollbar-button {
    display: none;
}

.DivScroolableStyledHidden::-webkit-scrollbar-track {
    display: none !important;
    visibility: hidden !important;
}

.DivScroolableStyledHidden::-webkit-scrollbar-thumb {
    background: white !important;
    visibility: hidden !important;
}

.DivScroolableStyledHidden::-webkit-scrollbar {
    visibility: hidden !important;
}