.TextInputLabel{
    color: #313E44;
    padding-bottom: 16px;
    line-height: 20px;
}

.TextInputField{
    border-radius: 16px;
    border: solid 1px;
    color: #313E44;
    font-size: 16px;
    line-height: 18px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: calc(100% - 50px);
}
