.showSwitch {
    height: auto;
    opacity: 1;
    transition: opacity 0.2s linear, height 0.2s linear;
}

.showSwitchPreload {
    height: auto;
    opacity: 1;
    transition: opacity 0.4s linear, height 0.5s linear;
}

.hideSwitch {
    overflow-y: hidden !important;
    height: 0px !important;
    opacity: 0.4;
    transition: opacity 0.6s linear, height 0.6s linear;
}

.showSwicthBackground{
    background-color: #00000066;
    transition: background-color 0.6s linear;
}

.hideSwicthBackground{
    background-color: #00000000;
    transition: background-color 0.6s linear;
}
