@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
.TourButton {
    color: #269ACF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #269ACF;
    background-color: white;
    cursor: pointer;
}