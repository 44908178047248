.suggest_install_button, .suggest_close_button{
    border: none;
    outline: none;
    cursor: pointer;
    top: 0; 
}
.suggest_install_div table{
    vertical-align: top;
    width: 100%;
}
.suggest_install_div{
    display: block;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 16px 20px 16px 20px;
    background-color: #C8DDE1;
    color: black;
    font-size: 14px;
    font-family: "Space Grotesk";
    text-align: left;
    text-align: center;
}
.suggest_close_button{
    color: grey;
    background-color: transparent;
    font-size: 30px;
    line-height: 26px;
    padding: 0;
    width: 26px;
    height: 26px;
}
.suggest_install_button{
    margin-top: 10px;
    background-color: #313A56;
    color: #FFFFFF;
    border-radius: 50px;
    font-size: 16px;
    padding: 8px 20px 8px 20px;
    text-decoration: none;
}

.suggest_install_button:hover{
    color: #FFFFFF;
}