header{
    max-height: 100px;
}
main{
    margin-top: 180px;
}
body{
    background-color: "#fff" !important;
}

.horizontal-list {
    list-style: none;
    padding: 0;
    display: flex;
    height: 60;
    overflow-x: scroll;
    transition: transform 0.4s;
}

.horizontal-list li {
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
    text-align: center;
}
