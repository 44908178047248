.OrangeButton{
    color: #FF6E22;
    background-color: #FEEEE6;
    outline: none;
    border: none;
    padding: 5px 16px 5px 16px;
    gap: 10px;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    font-size: 14px;
}