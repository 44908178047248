/*.ProfileModal tr{
    display: block;
    width: 200px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 30px;
    border: solid 1px;
    text-align: center;
}
.ProfileModal td{
    text-align: center;
    width: 200px;
}

.ProfileModal{
    width: 300px;
    height: 400px;
    margin: auto;
    margin-top: 80px;
    background-color: white;
}

.ProfileModal table{
    padding: 20px;
}*/

.ProfileMenuTable {
    margin: auto;
    margin-top: 4px;
    margin-bottom: 4px;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    overflow: hidden;
    width: 280px;
    color: white;
    text-align: center;
}

.ProfileMenuTable td {
    width: 50%;
    padding: 10px;
    cursor: pointer;
}

.ProfileMenuTable td:hover {
    opacity: 0.9;
}

.ProfileMenuTable td:active {
    opacity: 0.8;
}

.ProfileMenuTable tr:nth-child(odd) td:nth-child(odd),
.ProfileMenuTable tr:nth-child(even) td:nth-child(even) {
    background-color: #3d85c6;
}


.ProfileMenuTable tr:nth-child(even) td:nth-child(odd),
.ProfileMenuTable tr:nth-child(odd) td:nth-child(even) {
    background-color: #145d7d;
}

.ProfileMenuTable td[colspan="2"] {
    background-color: #6fa8dc !important;
}

.ctr {
    width: 100%;
    text-align: center;
    padding: 0;
}

.white_board,
.white_board:active,
.white_board:hover {
    background-color: #EEF7FF;
    color: black;
    height: 60px;
    font-weight: 400;
    opacity: 1 !important;
    text-align: left;
    padding: 4px;
    cursor: default;
}
.familyListItem{
    margin-top: 4px;
    margin-bottom: 8px;
    padding: 10px;
    border-radius: 10px;
    background-color: rgb(245, 248, 255);
    min-height: 40px;
}
.familyList{
    height: 135px;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: scroll;
}