.custom-dropdown {
    position: relative;
    display: inline-block;
    text-align: left;

    tap-highlight-color: transparent;
    -moz-user-select: none;
    -ms-user-select: none;
    box-shadow: none;
    outline: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-tap-highlight-color: transparent;
}

.dropdown-header {
    cursor: pointer;
    padding: 4px;
    width: 55px;
    border: 1px solid black;
    border-radius: 4px;
    background-color: white;
}
.dropdown-header:hover, .dropdown-header:active {
    cursor: pointer;
    padding: 4px;
    width: 55px;
    border: 1px solid #269ACF;
    background-color: white;
}

.dropdown-header.open {
    border-radius: 4px 4px 0px 0px;
    width: 55px;
    border-bottom: 1px solid white;
}

.dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid black;
    border-radius: 0px 0px 4px 4px;
    border-top: none;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 63px;
    z-index: 1000;
}

.dropdown-option {
    padding: 10px;
    cursor: pointer;
    border-bottom: none;
}

.dropdown-option:last-child {
    border-bottom: none;
}