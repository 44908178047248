.taskCardThumb{
    background-color: white;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    width: calc(100% - 60px);
    border-radius: 24px;
}
.taskCardThumb_text, .taskCardThumb_image{
    display: inline-block;
}
.taskCardThumb_text{
    width: calc(100% - 58px);
}
.taskCardThumb_image{
    width: 58px;
}