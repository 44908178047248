.AppPageHeader{
    color: black;
    background-color: white;
}
.AppPageHeader button{
    display: inline-block;
    padding: 20px;
}
.AppPageHeader div{
    display: inline-block;
    padding: 20px;
    text-align: center;
}
.AppPageHeader .btn_div button{
    background-color: white;
    border: none;
}
.AppPageHeader .btn_div{
    min-width: 60px;
    padding: 0;
    margin: 0;
    margin-top: -10px;
}
.ttl_div{
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.AppPageHeader .ttl_div{
    width: calc(100% - 150px) !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    color: black;
    background-color: white;
}