.the_calendar_select_month {
    position: relative;
    display: inline-block;
    user-select: none;
    cursor: pointer;
    border: none;
    width: '110px';
    height: 190px;
    overflow-y: scroll;
    overflow-x: hidden;
}

#theCalendarFirstDiv {
    
    width: 280px;
    margin: auto;
    margin-top: 16px;
    background: #F5F8FF;
    
    border-radius: 12px;
}

.the_calendar_select_month input[type="radio"] {
    position: absolute;
    top: -9999em;
}

.the_calendar_main_header {
    width: 50%;
    height: 40px;
    background: #F5F8FF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
}

.the_calendar_select_month label {
    padding: 4px;
    margin: 4px;
    text-align: center;
    width: 100%;
    background: #F5F8FF;
    display: block;
    cursor: pointer;
    transition: background 0.2s;
}

.the_calendar_select_month label:hover {
    background: #e0e0e0;
}

.the_calendar_select_month .select-options {
    display: none;
    text-align: center;
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #ccc;
    border-top: none;
    background: #fff;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
}

.the_calendar_select_month input[type="radio"]:checked+label {
    background-color: #496177;
    margin: auto;
    text-align: center;
    color: #fff;
    width: 80px;
    white-space: nowrap;
    border-radius: 12px;
}

.the_calendar_select_month input[type="radio"]+label {
    display: block;
    white-space: nowrap;
}

.the_calendar_select_month:hover .select-options {
    display: block;
}

#the_calendar_table {
    width: 100%;
}

#the_calendar_current_month {
    color: #313A56;
}

#the_calendar_current_year {
    vertical-align: middle;
}

#the_calendar_selector {
    color: #313A56;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    border: 0;
    padding: 4px;
    background: #F5F8FF;
    width: 262px;
}

.the_calendar_dropdown_button {
    background: transparent;
    border: 0;
    color: #313A56;
}

.the_calendar_dropdown_button_ {
    background: transparent;
    cursor: pointer;
    border: 0;
}

#the_calendar_prev_year_button,
#the_calendar_next_year_button {
    cursor: pointer;
    border: 0;
}

.the_calendar_dropdown_container {
    margin: 5px;
    padding: 5px;
    background: transparent;
    border: 0;
    width: 280px;
    border-radius: 12px
}

.the_calendar_dropdown_container_ {
    vertical-align: middle;
}

.the_calendar_dropdown_container,
.the_calendar_dropdown_container_ {
    position: relative;
    display: inline-block;
}

.the_calendar_dropdown_button,
.the_calendar_dropdown_button_ {
    cursor: pointer;
}

.the_calendar_dropdown_content {
    display: none;
    position: absolute;
    top: -7px;
    left: -4px;
    z-index: 1;
}

.the_calendar_dropdown_content_ {
    display: none;
    position: absolute;
    background-color: #F5F8FF;
    padding: 0px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    z-index: 2;
}

.the_calendar_dropdown_content a {
    display: block;
    padding: 12px 16px;
    text-decoration: none;
    color: #333;
}

#the_calendar_prev_year_button,
#the_calendar_next_year_button {
    width: 24px;
    height: 24px;
    vertical-align: middle;
}

.the_calendar_dropdown_content a:hover {
    background-color: #ddd;
}

#the_calendar_prev_next {
    text-align: right;
}

#the_calendar_week {
    font-size: 12px;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: 0;
    -webkit-border-radius: 10px;
    margin-top: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: transparent;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: transparent;
}