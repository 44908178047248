.ButtonSecondary{
    font-size: 16px;
    text-align: center;
    border: 2px solid;
    border-color: #313A56;
    color: #313A56;
    background-color: transparent;
}
.ButtonSecondary:hover{
    color: #FFF;
    background-color: #496177;
    border: none;
}
.ButtonSecondary:active{
    background-color: #161D33;
    color: #FFF;
    border: none;
}
.ButtonSecondary:disabled{
    border: none;
    background-color: #CBCCCD;
    color: #606060;
}
