@font-face {
  font-family: 'Space Grotesk';
  src: url('fonts/SpaceGrotesk-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik';
  src: url('fonts/Rubik-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'RubikBold';
  src: url('fonts/Rubik-Bold.ttf') format('truetype');
}

.no_select {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
}

body {
  font-family: 'Rubik';
  margin: 0;
  height: 100vh;
  padding: 0;
  color: #313A56;
}

img {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  height: 0.25vh;
}


.black_header_txt {
  color: #000000;
  font-weight: 400;
  font-size: 20px;
  vertical-align: middle;
  line-height: 18.96px;
  font-family: Rubik;
}

.base_background {
  background-color: #FAF7F2;
}

._green,
.green_txt {
  color: #0B7F6C;
  line-height: 16px;
  font-size: 16px;
  font-family: Rubik;
  vertical-align: middle;
  text-decoration: none;
}

/*
.green_txt{
  color: #0B7F6C;
  font-size: 16px;
  line-height: 18.96px;
  font-family: Rubik;
  vertical-align: middle;
}
*/

._black {
  color: #000;
  line-height: 18px;
  font-size: 16px;
  font-family: Rubik;
  font-weight: 400 !important;
}

._grey_16 {
  color: #6B6E70;
  line-height: 20px;
  font-size: 16px;
  font-family: Rubik;
  font-weight: 400 !important;
}

._grey_14 {
  color: #6B6E70;
  line-height: 16px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 400 !important;
}

._black_20_bold {
  color: #000;
  line-height: 22px;
  font-size: 20px;
  font-family: RubikBold;
  font-weight: 400 !important;
}

._black_20 {
  color: #000;
  line-height: 24px;
  font-size: 20px;
  font-family: Rubik;
  font-weight: 400 !important;
}

._black_32 {
  color: #000;
  line-height: 51px;
  font-size: 32px;
  font-family: Rubik;
  font-weight: 400 !important;
}

._black_28 {
  color: #000;
  line-height: 36px;
  font-size: 32px;
  font-family: Rubik;
  font-weight: 400 !important;
}

._black_bold {
  color: #000;
  line-height: 16px;
  font-size: 16px;
  font-family: RubikBold;
  font-weight: 400 !important;
}

._black_bold_18 {
  color: #000;
  line-height: 21px;
  font-size: 18px;
  font-family: RubikBold;
  font-weight: 500 !important;
}

._black_italic {
  color: #000;
  line-height: 16px;
  font-size: 16px;
  font-family: Rubik;
  font-weight: 400 !important;
}

.full_window {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;

  overflow: hidden;
}