.CalendarExtentionTable{
    /*
    
    */
    border-collapse: separate;
    border-spacing: 5px; 
    margin: auto;
}
.CalendarExtentionHeader{
    font-size: 12px;
    padding: 2px;
}
.CalendarExtentionHeader th{
    text-align: center;
    width: 32px !important;
}
.CalendarExtentionWeek td{
    cursor: pointer;
    font-family: 'Space Grotesk';
    text-align: center;
    width: 31px;
    padding: 1px;
    height: 31px;
    border-radius: 6px;
    font-weight: 500;
}
.CalendarExtentionCurrentDay{
    width: 29px;
    height: 29px;
    border: 2px solid var(--btn, #313A56);
}
.CalendarExtentionSelectedDay{
    width: 29px;
    height: 29px;
    border: 2px solid var(--btn, #313A56);
    background-color: #313A56 !important;
    color: whitesmoke;
}