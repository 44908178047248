.ButtonBluePrimary {
    font-size: 16px;
    text-align: center;
    border: none;
    color: #FFF;
    background-color: #269ACF;
}

.ButtonBluePrimary:hover {
    color: #FFF;
    background-color: #075F88;
    border: none;
}

.ButtonBluePrimary:active {
    background-color: #2080AC;
    color: #FFF;
    border: none;
}

.ButtonBluePrimary:disabled {
    border: none;
    background-color: #CBCCCD;
    color: #606060;
}