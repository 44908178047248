.LogoutButton{
    width: 32px;
    height: 32px;
    cursor: pointer;
    touch-action: none;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    padding: 0px;
}
.LogoutButton img {
    width: 32px;
    height: 32px;
}
.LogoutButton .LogoutButton_default {
    display: block;
}

.LogoutButton .LogoutButton_click,
.LogoutButton .LogoutButton_hover {
    display: none;
}

.LogoutButton:active .LogoutButton_default,
.LogoutButton:active .LogoutButton_hover {
    display: none !important;
}

.LogoutButton:active .LogoutButton_click {
    display: block !important;
}

.LogoutButton:hover .LogoutButton_default,
.LogoutButton:hover .LogoutButton_click {
    display: none;
}

.LogoutButton:hover .LogoutButton_hover {
    display: block;
}