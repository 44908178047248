/*.WelcomeDetails_root{
    padding: 0;   
    margin: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    overflow-x: hidden;
    overflow-y: scroll; 
}*/

.WelcomeDetails_root{
    /*padding: 0; */
    margin: 0;
    width: 100%;
    height: 100vh;
    padding-bottom: 10px;
    position: fixed;
    top: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.WelcomeDetails_footer, .WelcomeFooter{
    position: fixed;
    height: 50px;
    z-index: 25 ;
    bottom: 19px;
    left: 10px;
    right: 10px;
    padding-top: 10px;
    width: calc(100% - 24px);
}

.WelcomeDetails_title{
    padding-top: 25px;
    padding-left: 16px;
    padding-right: 16px;
}

.WelcomeDetails_form{
    background-color: white;
    padding: auto;
    
    border-radius: 24px;
    padding: 24px;
    margin: 16px;
}

.WelcomeFooter_{
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    bottom: 0;
    padding-bottom: 20px;
}