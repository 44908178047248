.ButtonPrimary{
    font-size: 16px;
    text-align: center;
    border: none;
    color: #FFF;
    background-color: #313A56;
}
.ButtonPrimary:hover{
    color: #FFF;
    background-color: #496177;
    border: none;
}
.ButtonPrimary:active{
    background-color: #161D33;
    color: #FFF;
    border: none;
}
.ButtonPrimary:disabled_{
    border: none !important;
    background-color: #CBCCCD !important;
    color: #606060 !important;
}