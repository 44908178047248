.WelcomeLogo{
    margin-top: 70px;
    max-width: 200px;
    max-height: 108px;
    margin-left: calc(50% - 100px);
    margin-right: calc(50% - 100px);
}

.WelcomeTo_root{
    padding: 0;   
    margin: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.welcomeTiitle, .welcomeDescription{
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.welcomeTiitle{
    padding: auto;   
}

.welcomeDescription{
    padding: auto;   
}

.welcomeImages{
    margin-top: 72px;
    width: calc(100% - 50px);
    margin-left: 24px;
    margin-right: 24px;
}

.welcomeImageDiv{
    background-color: white;
    width: 70%;
    padding: 24px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;;
    border-radius: 24px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.welcomeImageDivLine{
    padding: 0px;
    height: 4px;
    border-radius: 4px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 20px;
    margin-right: 20px;
}

.img_Welcome{
    width: 48px;
    height: 48px;
}

.img_WelcomeDiv{
    display: inline-block;
    width: 48;
}

.img_WelcomeDiv_2{
    display: inline-block;
    width: calc(100% - 50px);
}