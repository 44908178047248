.react-time-picker__wrapper, .date_str{
    margin-top: 10;
    width: 100%;
    font-size: 16px !important;
   outline: none;
    color: #6C8286 !important;
    cursor: pointer;
    background-color: white !important;
    border: 1px solid #C8DDE1 !important;
    border-radius: 8px !important;
}
.react-time-picker{
    width: 95%;
}
.date_str{
    padding: 10px;
}
.react-datepicker-wrapper{
    width: 86%;
    margin-left: 20;
    margin-right: 20;
}
.react-time-picker__wrapper{
    margin-top: 10px;
    padding: 8px 12px !important;
    margin-left: 20;
    margin-right: 20;
}
.react-time-picker__clock, .react-time-picker__clock-button{
    display: none !important;
}