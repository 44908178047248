.UserMenuButton_default {
    border-radius: 19px;
    width: 38px;
    height: 38px;
}

.UserMenuButton:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.UserMenuButton:active {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.37);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.37);
}

.UserMenuButton {
    width: 38px;
    height: 38px;
    background-color: transparent;
    border-radius: 19px;
    padding: 0;
    cursor: pointer;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
}