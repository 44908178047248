.AppButtonPrimary {
    color: #fff;
    background-color: #0B7F6C;
    padding: 12px 20px;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border: none;
    font-family: Rubik;
    font-size: 18px;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 200px;
    border: none;
}