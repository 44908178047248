.ButtonBlueSecondary {
    font-size: 16px;
    text-align: center;
    border: 2px solid;
    border-color: #269ACF;
    color: #269ACF;
    background-color: transparent;
}

.ButtonBlueSecondary:hover {
    color: #FFF;
    background-color: #075F88;
    border: none;
}

.ButtonBlueSecondary:active {
    background-color: #2080AC;
    color: #FFF;
    border: none;
}

.ButtonBlueSecondary:disabled {
    border: none;
    background-color: #CBCCCD;
    color: #606060;
}